export class CadenceError extends Error {
  error: any

  constructor(e: any) {
    super(e.error?.message || e.statusText || 'Error')
    this.name = this.constructor.name
    this.error = e

    const {captureStackTrace} = Error as any
    if (captureStackTrace) {
      captureStackTrace(this, CadenceError)
    }
  }

  public override toString(): string {
    return this.message
  }

  public getMessage(replacements?: {[from: string]: string}): string {
    return replacements && replacements[this.message] ? replacements[this.message] : this.message
  }

  get hasValidationErrors(): boolean {
    return this.error.status === 422 && typeof this.error.error?.errors === 'object'
  }

  get validationErrors(): object {
    return this.error.error?.errors || {}
  }

  static makeMessageFor(validationErrors: object): string {
    // these are error messages generated automatically by laravel via request validation
    // they are in the form:
    /*
     {
      errors: {
        fieldName: ["Error message 1", "Error message 2"],
        anotherFieldName: ["Another error"],
      }
     }
    */
    // Just write the first error into the error message, for each field that has an error.
    const errorString = Object.entries(validationErrors)
      .map(([field, errors]) => `<li>${errors[0]}</li>`)
      .join('')
    return `Invalid data: <br><ul class="mb-0">${errorString}</ul>`
  }
}
